import Vue from 'vue';

Vue.mixin({
  methods: {
    toast(content, title = null, variant = 'danger') {
      // Use a shorter name for this.$createElement
      const h = this.$createElement;
      // Create the message
      const vNodesMsg = h('p', { class: ['text-center', 'mb-0'] }, [this.trans(content)]);

      // Pass the VNodes as an array for message
      this.$bvToast.toast([vNodesMsg], {
        title: title ?? this.trans(`general.${variant}`),
        autoHideDelay: 25000,
        appendToast: true,
        variant,
        solid: true,
      });
    },
  },
});
